// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential
import getEnv from '@/utils/env'

import { initToolsProvidencePayload, defaultProvidenceSessionTools } from './initToolsProvidence.js'
import { initToolsUPennPayload, defaultUPennSessionTools } from './initToolsUPenn.js'

export const initToolsPayload = getEnv('VUE_APP_UPENN_BUILD') === 'true' ? initToolsUPennPayload : initToolsProvidencePayload

export const defaultSessionTools = getEnv('VUE_APP_UPENN_BUILD') === 'true' ? defaultUPennSessionTools : defaultProvidenceSessionTools
