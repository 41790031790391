// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { createStore } from 'vuex'

import { actions } from './actions.js'
import { defaultFeatureToggles } from './featureToggles.js'
import { getters } from './getters.js'
import { mutations } from './mutations.js'

export const updateActivityTracker = (state) => {
  state.lastActivitySecondsSinceEpoch = Math.round((new Date().getTime()) / 1000)
}

const state = () => {
  const allAvailableTools = {}
  const allUsers = []
  const authResponse = {}
  const featureToggles = defaultFeatureToggles
  const profile = {}
  const session = {}
  const sessionTemplates = []
  const showSystemToast = ''

  return {
    allAvailableTools,
    allUsers,
    authResponse,
    featureToggles,
    lastActivitySecondsSinceEpoch: Math.round((new Date().getTime()) / 1000),
    profile,
    session,
    sessionTemplates,
    showSystemToast,
  }
}

// exported for testing purposes.
export const testStore = {
  state,
  mutations,
  actions,
  getters,
}

export const store = createStore(testStore)
