// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { formatDistance } from 'date-fns'

import { defaultThemeName, getDashboardUrl, getProfileUrl, getSessionUrl, isThemeLight, languageToLocale, parseJwt, themeColors } from '@/common/shared.js'
import { translateFunc } from '@/plugins/translation.js'

import { defaultFeatureToggles } from './featureToggles.js'

export const getters = {
  allAccessibleSessions(state) {
    if (!state.profile || !state.profile.sessions) { return [] }
    return Object.values(state.profile.sessions)
  },
  allUsers(state) {
    return state.allUsers || []
  },
  checkboxColor(state) {
    const currentThemeName = getters.currentThemeName(state)
    return themeColors[currentThemeName].inputElementBorderColor
  },
  currentThemeName(state) {
    return state.profile.currentThemeName || defaultThemeName()
  },
  dashboardUrl(state) {
    return getDashboardUrl(getters.urlSafeUserId(state))
  },
  featureToggles(state) {
    return state.featureToggles || defaultFeatureToggles
  },
  font(state) {
    const language = state.profile.language
    if (language === 'zh_CN.UTF-8') {
      return '"Noto Sans SC", sans-serif'
    }
    if (language === 'zh_TW.UTF-8') {
      return '"Noto Sans TC", sans-serif'
    }
    return '"Roboto", sans-serif'
  },
  formatDateDistance: (state) => (date, baseDate) => {
    const locale = languageToLocale[state.profile.language]
    const options = { locale, addSuffix: true }
    return formatDistance(date, baseDate, options)
  },
  lastActivitySecondsSinceEpoch: (state) => state.lastActivitySecondsSinceEpoch,
  isLightMode(state) {
    return !!isThemeLight[state.profile.currentThemeName]
  },
  isSessionReadOnly(state) {
    const myId = getters.userId(state)
    if (!state.session || !state.session.id || !state.profile.sessions) { return true }
    if (state.session.ownerId === myId) { return false }
    const viewers = state.profile.sessions[state.session.id].viewers || []
    return state.session.viewerWithAnyoneLink || viewers.includes(myId)
  },
  jwt(state) {
    if (!state.authResponse || !state.authResponse.access_token) { return '' }
    return state.authResponse.access_token
  },
  jwtIsValid(state) {  // returns a function that requires (new Date())!
    const jwt = getters.jwt(state)
    if (!jwt) { return () => false }
    const payload = parseJwt(jwt)
    const expires = new Date(0)
    expires.setUTCSeconds(payload.exp)
    return (now) => expires > now
  },
  mostRecentSession(state) {
    if (!state.profile || !state.profile.sessions) { return false }
    const sessionVals = Object.values(state.profile.sessions)
    if (sessionVals.length > 0) {
      const mostRecent = sessionVals.reduce((o, v) => {
        return o.lastAccessed > v.lastAccessed ? o : v
      }, { lastAccessed: 0 })
      return mostRecent
    }
    return false
  },
  mostRecentSessionUrlOrDashboard(state) {
    if (!getters.jwt(state)) { return '' }
    const userName = getters.urlSafeUserId(state)
    const dashboardUrl = getDashboardUrl(userName)
    const mostRecentSession = getters.mostRecentSession(state)
    if (!mostRecentSession) { return dashboardUrl }
    const sessionName = mostRecentSession.name || mostRecentSession.id
    const ownerName = mostRecentSession.ownerName || mostRecentSession.ownerId
    const url = getSessionUrl(ownerName, sessionName)
    return url
  },
  onReloadTool(state) {
    return (toolId) => {
      if (!toolId) { return () => { } }
      return () => {
        const tool = getters.toolById(state)(toolId)
        if (tool && tool.onReloadTool) {
          tool.onReloadTool(tool.payload)
        }
      }
    }
  },
  profile(state) {
    return state.profile
  },
  profileUrl(state) {
    return getProfileUrl(getters.urlSafeUserId(state))
  },
  selectedMeeting(state) {
    return state.session.meeting || {}
  },
  session(state) {
    return state.session || {}
  },
  sessionAnonymized(state) {
    if (!state.session) { return false }
    return state.session.locallyAnonymized
  },
  sessionById(state) {
    if (!state.profile || !state.profile.sessions) { return () => null }
    return (id) => state.profile.sessions[id]
  },
  sessionId(state) {
    if (!state.session) { return '' }
    return state.session.id
  },
  sessionOwnerId(state) {
    if (!state.session) { return '' }
    return state.session.ownerId
  },
  sessionsOwnedByMe(state) {
    if (!state.profile || !state.profile.sessions) { return [] }
    // Sort results from newest to oldest.
    const sessionVals = Object.values(state.profile.sessions)
    const myId = getters.userId(state)
    const sessions = sessionVals.filter((s) => s.ownerId === myId)
    sessions.sort((a, b) => b.lastAccessed - a.lastAccessed)
    return sessions
  },
  sessionsSharedWithMe(state) {
    if (!state.profile || !state.profile.sessions) { return [] }
    const sessionVals = Object.values(state.profile.sessions)
    const myId = getters.userId(state)
    const sessions = sessionVals.filter((s) => s.ownerId !== myId)
    sessions.sort((a, b) => b.lastAccessed - a.lastAccessed)
    return sessions
  },
  sessionTemplates(state) {
    return state.sessionTemplates
  },
  sessionTools(state) {
    const roles = getters.userRoles(state)
    const toolRoles = Object.keys(state.allAvailableTools)
    if (roles.length === 0 || toolRoles.length === 0) { return [] }
    const dedupeTools = roles.reduce((acc, role) => {
      const roleTools = state.allAvailableTools[role]
      const toolIds = Object.keys(roleTools || [])
      toolIds.forEach((id) => acc[id] = roleTools[id])
      return acc
    }, {})
    const language = state.profile.language
    let tools = Object.values(dedupeTools)
    const isChartLaunchedPatient = state.session.patient?.isChartLaunchedPatient
    if (isChartLaunchedPatient) {
      tools = tools.filter(tool => !tool.omitFromPatientChartLaunch)
    }
    return tools.sort((a, b) => a.name[language].localeCompare(b.name[language]))
  },
  showSystemToast: (store) => store.showSystemToast,
  toolById(state) {
    return (toolId) => {
      const toolIndex = getters.toolsMapIndex(state)[toolId]
      const tool = getters.tools(state)[toolIndex]
      return tool
    }
  },
  tools(state) {
    return state.session.tools || []
  },
  toolsMapIndex(state) {
    const lookup = {}
    if (state.session && state.session.tools) {
      state.session.tools.forEach((obj, index) => lookup[obj.id] = index)
    }
    return lookup
  },
  translate(state) {  // This is provided for parts of the app that can't inject and call tr().
    const language = state.profile.language
    return (key) => translateFunc(language, key)
  },
  tumorBoardAttendanceData(state) {
    return state.session.tumorBoardAttendanceData || {}
  },
  tumorBoardSessionsData(state) {
    return state.session.tumorBoardSessionsData || {}
  },
  urlSafeUserId(state) {
    return (state.profile.isUniqueDisplayName && state.profile.displayName) || getters.userId(state)
  },
  userId(state) {
    const jwt = getters.jwt(state)
    if (!jwt) { return '' }
    const payload = parseJwt(jwt)
    return payload.sub
  },
  userRoles(state) {
    const jwt = getters.jwt(state)
    if (!jwt) { return [] }
    const payload = parseJwt(jwt)
    if (!payload || !payload.realm_access) { return [] }
    return payload.realm_access.roles || []
  },
}
