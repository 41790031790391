// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, ref, watch } from 'vue'

import { store } from '@/store'
import getEnv from '@/utils/env'

export default {
  setup(app, { client, publishToKey }) {
    // channel: v1/users/#/ (Read, Write, and Presence permissions)
    const userChannelKey = getEnv('VUE_APP_PUBSUB_USER_CHANNEL_KEY')
    const publishClient = publishToKey(userChannelKey)
    const userChannelBaseName = computed(() => store.getters.userId ? `v1/users/${store.getters.userId}` : '')

    const subscribedTo = ref('')
    watch(userChannelBaseName, (newValue) => {
      if (subscribedTo.value) {
        client.unsubscribe({
          key: userChannelKey,
          channel: `${subscribedTo.value}/`,
        })
        subscribedTo.value = ''
      }
      if (newValue) {
        client.subscribe({
          key: userChannelKey,
          channel: `${newValue}/`,
        })
        subscribedTo.value = newValue
      }
    })

    const addSessionSharing = ({ session, userId, publish }) => {
      if (!publish) { return }
      const channel = `v1/users/${userId}/profile/createNewSession`
      const message = JSON.stringify(session)
      publishClient({ channel, message })
    }

    const changeConfirmBeforeDeletingSession = ({ confirmBeforeDeletingSession, publish }) => {
      if (!publish) { return }
      const message = `${confirmBeforeDeletingSession}`
      publishProfileField('confirmBeforeDeletingSession', message)
    }

    const changeCurrentThemeName = ({ themeName, publish }) => {
      if (!publish) { return }
      publishProfileField('currentThemeName', themeName)
    }

    const changeDarkTheme = ({ darkThemeName, publish }) => {
      if (!publish) { return }
      publishProfileField('darkThemeName', darkThemeName)
    }

    const changeDisplayName = ({ displayName, publish }) => {
      if (!publish) { return }
      publishProfileField('displayName', displayName)
    }

    const changeLanguage = ({ language, publish }) => {
      const setTranslationLanguage = app.config.globalProperties.$setTranslationLanguage
      setTranslationLanguage(language)
      if (!publish) { return }
      publishProfileField('language', language)
    }

    const changeLightTheme = ({ lightThemeName, publish }) => {
      if (!publish) { return }
      publishProfileField('lightThemeName', lightThemeName)
    }

    const changeTimeZone = ({ timeZone, publish }) => {
      if (!publish) { return }
      publishProfileField('timeZone', timeZone)
    }

    const createNewSession = ({ session, publish }) => {
      if (!publish) { return }
      const channel = `${userChannelBaseName.value}/profile/createNewSession`
      publishClient({
        channel,
        message: JSON.stringify(session),
      })
    }

    const getProfile = () => {
      publishProfileField('get', store.getters.jwt)
    }

    const publishProfileField = (fieldName, message) => {
      const channel = `${userChannelBaseName.value}/profile/${fieldName}`
      publishClient({ channel, message })
    }

    const removeSessionSharing = ({ sessionId, userId, publish }) => {
      if (!publish) { return }
      const channel = `v1/users/${userId}/profile/deleteSession`
      publishClient({ channel, message: sessionId })
    }

    const replaceAllTools = ({ tools, publish }) => {
      if (!publish || !store.getters.sessionId) { return }
      const userId = store.getters.userId
      const channel = `v1/users/${userId}/profile/toolCount`
      const sessionId = store.getters.sessionId
      const msg = {
        ownerId: userId,
        sessionId,
        toolCount: tools.length,
      }
      const message = JSON.stringify(msg)
      publishClient({ channel, message })
      store.dispatch('setSessionToolCount', msg)
      // update all collaborators and viewers of this session
      const collaborators = store.getters.profile.sessions[sessionId]?.collaborators || []
      collaborators.forEach((id) => {
        const channel = `v1/users/${id}/profile/toolCount`
        publishClient({ channel, message })
      })
      const viewers = store.getters.profile.sessions[sessionId]?.viewers || []
      viewers.forEach((id) => {
        const channel = `v1/users/${id}/profile/toolCount`
        publishClient({ channel, message })
      })
    }

    const setSessionLastAccessed = ({ ownerId, sessionId, lastAccessed, publish }) => {
      if (!publish) { return }
      const userId = store.getters.userId
      const channel = `v1/users/${userId}/profile/setSessionLastAccessed`
      const message = JSON.stringify({
        ownerId,
        sessionId,
        lastAccessed,
      })
      publishClient({ channel, message })
    }

    const setSessionLocallyAnonymized = ({ ownerId, sessionId, locallyAnonymized, publish }) => {
      if (!publish) { return }
      const userId = store.getters.userId
      const channel = `v1/users/${userId}/profile/setSessionLocallyAnonymized`
      const message = JSON.stringify({
        ownerId,
        sessionId,
        locallyAnonymized,
      })
      publishClient({ channel, message })
    }

    const setSessionName = ({ userId, ownerId, sessionId, sessionName, publish }) => {
      if (!publish) { return }
      const channel = `v1/users/${userId}/profile/setSessionName`
      const message = JSON.stringify({
        userId,
        ownerId,
        sessionId,
        sessionName,
      })
      publishClient({ channel, message })
    }

    const setSessionOwnerName = ({ userId, ownerId, sessionId, sessionOwnerName, publish }) => {
      if (!publish) { return }
      const channel = `v1/users/${userId}/profile/setSessionOwnerName`
      const message = JSON.stringify({
        userId,
        ownerId,
        sessionId,
        sessionOwnerName,
      })
      publishClient({ channel, message })
    }

    const setSessionSharing = ({ ownerId, sessionId, collaborateWithAnyoneLink, collaborators, viewerWithAnyoneLink, viewers, publish }) => {
      if (!publish) { return }
      const userId = store.getters.userId
      const channel = `v1/users/${userId}/profile/setSessionSharing`
      const message = JSON.stringify({
        ownerId,
        sessionId,
        collaborateWithAnyoneLink,
        collaborators,
        viewerWithAnyoneLink,
        viewers,
      })
      publishClient({ channel, message })
    }

    const toggleTheme = (_, state) => {
      // NOTE: store is not available here, only state, so can not use getter:
      const currentThemeName = state.profile.currentThemeName
      publishProfileField('currentThemeName', currentThemeName)
    }

    const usersBeforeActions = ref({
      'addSessionSharing': addSessionSharing,
      'changeConfirmBeforeDeletingSession': changeConfirmBeforeDeletingSession,
      'changeCurrentThemeName': changeCurrentThemeName,
      'changeDarkTheme': changeDarkTheme,
      'changeDisplayName': changeDisplayName,
      'changeFeatureToggles': () => { },
      'changeIsUniqueDisplayName': () => { },
      'changeLanguage': changeLanguage,
      'changeLightTheme': changeLightTheme,
      'changeTimeZone': changeTimeZone,
      'createNewSession': createNewSession,
      'getProfile': getProfile,
      'removeSessionSharing': removeSessionSharing,
      'replaceAllTools': replaceAllTools,
      'routeToUrl': () => { },
      'saveAllAvailableTools': () => { },
      'saveAuthResponse': () => { },  // handled by store/actions.js.
      'setSessionLastAccessed': setSessionLastAccessed,
      'setSessionLocallyAnonymized': setSessionLocallyAnonymized,
      'setSessionName': setSessionName,
      'setSessionOwnerName': setSessionOwnerName,
      'setSessionSharing': setSessionSharing,
      'setSessionToolCount': () => { },
    })

    const usersAfterActions = ref({
      'saveProfile': () => { },
      'toggleTheme': toggleTheme,
    })

    const usersHandlers = computed(() => {
      const obj = {}
      obj[`${userChannelBaseName.value}/profile/confirmBeforeDeletingSession/`] = (msg) => {
        store.dispatch('changeConfirmBeforeDeletingSession', msg.asObject())
      }
      obj[`${userChannelBaseName.value}/profile/createNewSession/`] = (msg) => {
        store.dispatch('createNewSession', { session: msg.asObject() })
      }
      obj[`${userChannelBaseName.value}/profile/currentThemeName/`] = (msg) => {
        store.dispatch('changeCurrentThemeName', { themeName: msg.asString() })
      }
      obj[`${userChannelBaseName.value}/profile/deleteSession/`] = (msg) => {
        const sessionId = msg.asString()
        store.dispatch('deleteSession', { sessionId })
      }
      obj[`${userChannelBaseName.value}/profile/displayName/`] = (msg) => {
        store.dispatch('changeDisplayName', { displayName: msg.asString() })
      }
      obj[`${userChannelBaseName.value}/profile/featureToggles/`] = (msg) => {
        store.dispatch('changeFeatureToggles', { featureToggles: msg.asObject() })
      }
      obj[`${userChannelBaseName.value}/profile/isUniqueDisplayName/`] = (msg) => {
        store.dispatch('changeIsUniqueDisplayName', { unique: msg.asObject() })
      }
      obj[`${userChannelBaseName.value}/profile/language/`] = (msg) => {
        store.dispatch('changeLanguage', { language: msg.asString() })
      }
      obj[`${userChannelBaseName.value}/profile/setSessionLastAccessed/`] = (msg) => {
        store.dispatch('setSessionLastAccessed', msg.asObject())
      }
      obj[`${userChannelBaseName.value}/profile/setSessionLocallyAnonymized/`] = (msg) => {
        store.dispatch('setSessionLocallyAnonymized', msg.asObject())
      }
      obj[`${userChannelBaseName.value}/profile/setSessionName/`] = (msg) => {
        store.dispatch('setSessionName', msg.asObject())
      }
      obj[`${userChannelBaseName.value}/profile/setSessionOwnerName/`] = (msg) => {
        store.dispatch('setSessionOwnerName', msg.asObject())
      }
      obj[`${userChannelBaseName.value}/profile/setSessionSharing/`] = (msg) => {
        store.dispatch('setSessionSharing', msg.asObject())
      }
      obj[`${userChannelBaseName.value}/profile/setSessionViewers/`] = (msg) => {
        store.dispatch('setSessionViewers', msg.asObject())
      }
      obj[`${userChannelBaseName.value}/profile/timeZone/`] = (msg) => {
        store.dispatch('changeTimeZone', { timeZone: msg.asString() })
      }
      obj[`${userChannelBaseName.value}/profile/toolCount/`] = (msg) => {
        store.dispatch('setSessionToolCount', msg.asObject())
      }
      return obj
    })

    return {
      usersAfterActions,
      usersBeforeActions,
      usersHandlers,
    }
  },
}
