// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import getEnv from '@/utils/env'

const upennBuild = getEnv('VUE_APP_UPENN_BUILD') === 'true'
const clinicianView = getEnv('VUE_APP_PRODUCT_FOCUS') === 'clinician'

export const defaultFeatureToggles = {
  chooseAdditionalThemes: getEnv('VUE_APP_CHOOSE_ADDITIONAL_THEMES') === 'true',
  clinicianView,
  dashboardMultiSessionSelect: getEnv('VUE_APP_DASHBOARD_MULTI_SESSION_SELECT') === 'true',
  enableSessionReadOnlySharing: getEnv('VUE_APP_ENABLE_SESSION_READ_ONLY_SHARING') === 'true',
  enableSessionSharing: getEnv('VUE_APP_ENABLE_SESSION_SHARING') === 'true',
  guardantBuild: getEnv('VUE_APP_GUARDANT_BUILD') === 'true',
  logoutAfterInactivityMinutes: getEnv('VUE_APP_LOGOUT_AFTER_INACTIVITY_MINUTES') || 0,
  noBackend: getEnv('VUE_APP_NO_BACKEND') === 'true',
  noProfile: getEnv('VUE_APP_NO_PROFILE') === 'true',
  providenceBuild: getEnv('VUE_APP_PROVIDENCE_BUILD') === 'true',
  upennBuild,
  researcherView: getEnv('VUE_APP_PRODUCT_FOCUS') === 'researcher',
}
