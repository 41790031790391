// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, ref } from 'vue'

import { store } from '@/store'
import { browserToLanguage } from '@/common/shared.js'
import getEnv from '@/utils/env'

export default {
  setup(_, { client, publishToKey }) {
    // channel: v1/rpc/#/ (Read, Write, Presence, and Extending permissions)
    const rpcChannelKey = getEnv('VUE_APP_PUBSUB_RPC_CHANNEL_KEY')

    const privateRPCChannelBaseName = ref('')
    const publishClient = ref(null)
    const loginCredentials = ref('')
    const redirectUrl = ref('')
    const establishRPCChannel = () => {
      client.on('keygen', (obj) => {
        if (obj.status === 200) {
          privateRPCChannelBaseName.value = obj.channel.substring(0, obj.channel.length - 3)
          client.subscribe({ key: obj.key, channel: privateRPCChannelBaseName.value + '/' })
          publishClient.value = publishToKey(obj.key)
          if (loginCredentials.value) {
            publishClient.value({
              channel: privateRPCChannelBaseName.value + '/onLogin',
              message: loginCredentials.value,
            })
            loginCredentials.value = ''
          }
          return
        }
        console.log('ERROR: unhandled keygen:', obj)
      })
      client.keygen({ key: rpcChannelKey, channel: 'v1/rpc/#/', type: 'rwe', ttl: 0 })
    }

    const getAllUsers = () => {
      // The response is via RPC: .../allUsersResponse
      publishClient.value({
        channel: privateRPCChannelBaseName.value + '/getAllUsers',
        message: '',
      })
    }

    const getSavedSession = (obj) => {
      // The response is in one or two parts:
      // First, if the session is owned by someone else and the current profile.session
      // does not contain the session listed, and it is shared with "anyone with the link",
      // then the profile.session is updated and sent (via RPC .../profile)
      // to include the session, and the rest should then work.
      // The rest of the session details are sent via Sessions: .../all
      publishClient.value({
        channel: privateRPCChannelBaseName.value + '/getSavedSession',
        message: JSON.stringify(obj),
      })
    }

    const duplicateSessionTools = ({
      srcSessionOwnerId,
      srcSessionId,
      dstSession,
    }) => {
      const message = JSON.stringify({
        srcSessionOwnerId,
        srcSessionId,
        dstSession,
      })
      // No response is sent from the backend.
      const channel = privateRPCChannelBaseName.value + '/duplicateSessionTools'
      publishClient.value({ channel, message })
    }

    const onLogin = (obj) => {
      if (obj.redirectUrl) {
        redirectUrl.value = obj.redirectUrl
      }
      if (obj.authResponse) {
        store.dispatch('saveAuthResponse', obj.authResponse)
      }
      if (publishClient.value !== null) {
        // The response is a full profile sent via RPC to .../profile.
        publishClient.value({
          channel: privateRPCChannelBaseName.value + '/onLogin',
          message: JSON.stringify(obj),
        })
      } else {
        loginCredentials.value = JSON.stringify(obj)
        establishRPCChannel()
      }
    }

    const rpcBeforeActions = ref({
      'duplicateSessionTools': duplicateSessionTools,
      'onLogin': onLogin,
      'saveAllUsers': () => { },
      'saveSessionTemplates': () => { },
      'showSystemToast': () => { },
      'startTutorial': () => { },
    })

    const rpcAfterActions = ref({
      'getAllUsers': getAllUsers,
      'getSavedSession': getSavedSession,  // MUST be an 'after' action.
    })

    const requestAllTools = () => {
      // The response is sent via RPC to .../allToolsResponse.
      publishClient.value({
        channel: privateRPCChannelBaseName.value + '/requestAllTools',
        message: '',
      })
    }

    const requestSessionTemplates = () => {
      if (!store.getters.featureToggles) { return }
      const productFocus = store.getters.featureToggles.clinicianView ? 'clinician' : 'researcher'
      // The response is sent via RPC to .../sessionTemplatesResponse.
      publishClient.value({
        channel: privateRPCChannelBaseName.value + '/requestSessionTemplates',
        message: productFocus,
      })
    }

    const allToolsResponseHandler = (msg) => {
      store.dispatch('saveAllAvailableTools', msg.asObject())
    }

    const allUsersResponseHandler = (msg) => {
      store.dispatch('saveAllUsers', msg.asObject())
    }

    const useBrowserLanguage = () => browserToLanguage[navigator.language] || 'en_US.UTF-8'

    const profileHandler = (msg) => {
      const profile = msg.asObject()
      const startTutorial = !!profile.startTutorial
      if (startTutorial) {
        profile.language = useBrowserLanguage()
        delete profile['startTutorial']  // Do not save in the profile.
      }
      store.dispatch('saveProfile', { profile })
      store.dispatch('changeLanguage', { language: profile.language })
      store.dispatch('changeConfirmBeforeDeletingSession', { confirmBeforeDeletingSession: profile.confirmBeforeDeletingSession })

      requestAllTools()
      requestSessionTemplates()
      if (startTutorial) {
        const url = store.getters.profileUrl
        store.dispatch('routeToUrl', url)
        setTimeout(() => store.dispatch('startTutorial'), 1000)
        return
      }
      if (redirectUrl.value && redirectUrl.value !== '/') {
        store.dispatch('routeToUrl', redirectUrl.value)
        return
      }
      const url = store.getters.mostRecentSessionUrlOrDashboard
      store.dispatch('routeToUrl', url)
    }

    const redirectHandler = (msg) => {
      const obj = msg.asObject()
      const url = obj.url || store.getters.dashboardUrl
      store.dispatch('routeToUrl', url)
      if (obj.message) {
        if (obj.message === 'not found') {
          const alertText = store.getters.translate('That session cannot be found.')
          // setTimeout(() => alert(alertText), 50)
          store.dispatch('showSystemToast', alertText)
          setTimeout(() => store.dispatch('showSystemToast', ''), 1000)
        } else if (obj.message === 'no access') {
          const alertText = store.getters.translate('You do not have access to that session.')
          // setTimeout(() => alert(alertText), 50)
          store.dispatch('showSystemToast', alertText)
          setTimeout(() => store.dispatch('showSystemToast', ''), 1000)
        } else {
          console.log(obj.message)
        }
      }
    }

    const sessionTemplatesResponseHandler = (msg) => {
      store.dispatch('saveSessionTemplates', msg.asObject())
    }

    const rpcHandlers = computed(() => {
      const obj = {}
      obj[`${privateRPCChannelBaseName.value}/allToolsResponse/`] = allToolsResponseHandler
      obj[`${privateRPCChannelBaseName.value}/allUsersResponse/`] = allUsersResponseHandler
      obj[`${privateRPCChannelBaseName.value}/profile/`] = profileHandler
      obj[`${privateRPCChannelBaseName.value}/redirect/`] = redirectHandler
      obj[`${privateRPCChannelBaseName.value}/sessionTemplatesResponse/`] = sessionTemplatesResponseHandler
      return obj
    })

    return {
      rpcAfterActions,
      rpcBeforeActions,
      rpcHandlers,
    }
  },
}
