// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { enUS, frCA, tr as trTR, zhCN, zhTW } from 'date-fns/locale'
import { customRef } from 'vue'
import pako from 'pako'

import styleVariables from './shared.scss'
import getEnv from '@/utils/env'

// Copied from https://stackoverflow.com/questions/36532307/rem-px-in-javascript
export function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

export const colPadding = 16
export const rowPadding = convertRemToPixels(parseFloat(styleVariables.rowPadding))
export const rowSize = 112
export const heightInPixelsFromRows = (toolHeightInRows) => rowSize * toolHeightInRows - rowPadding

export const numColumns = 12
export const maxToolRows = 20
export const minToolColumns = 2
export const minToolRows = 2

export const themeColors = {
  /*  'dātma dark': {
      // Unused by UX2, but used by BlackBoxSSRTools:
      alternatingTableRowsBackgroundColor1: '#292929',
      alternatingTableRowsBackgroundColor2: '#343434',
      tableBorderColor: 'transparent',
      tableCellSortLinkColor: '#000',
      tableCellTextColor: '#fff',
      tableCellTextLinkColor: '#68ae1b',
      tableHeaderCellColor: '#121212',
      tableHeaderTextColor: '#616161',
      tableLineColor: '#1e1e1e',
      toolBackgroundColor: '#1e1e1e',
      toolFieldBackgroundColor: '#343434',
      toolFieldTextColor: '#fff',
      toolIconColor: '#424242',
      toolInputBackgroundColor: '#343434',
      toolInputTextColor: '#fff',
      toolPulldownBackgroundColor: '#343434',
      toolPulldownTextColor: '#fff',
      toolScrollArrowsColor: '#fff',
      toolScrollBarBackgroundColor: '#292929',
      toolScrollBarColor: '#f46d4e',
      toolTextColor: '#fff',

      accentColor: '#6a8e1b',
      alternatingTableRowsBackgroundColor: '#292929',
      appScrollBarBackgroundColor: '#121212',
      appScrollBarColor: '#f46d4e',
      baseBackgroundColor: '#121212',
      baseHeaderBackgroundColor: '#000',
      baseHeaderIconColor: '#4c4c4c',
      baseHeaderTextColor: '#bfbfbf',
      buttonBackgroundColor: '#6a8e1b',
      buttonBorderColor: '#6a8e1b',
      buttonTextColor: '#1e1e1e',
      secondaryButtonBackgroundColor: '#525050',
      secondaryButtonBorderColor: '#6a8e1b',
      secondaryButtonTextColor: '#eee',
      confirmationPopupBackgroundColor: '#f46d4e',
      confirmationPopupTextColor: '#fff',
      errorBackgroundColor: '#3f5350',
      errorTextColor: '#fff',
      inputElementBorderColor: '#343434',
      modalBackgroundColor: '#1e1e1e',
      modalIconColor: '#616161',
      modalTextColor: '#fff',
      plotColor01: '#8abe1a',
      plotColor02: '#ffaa0f',
      plotColor03: '#f46d4e',
      plotColor04: '#df01be',
      productLogoColor: '#000',
      spinnerStrokeColor: '#df01be', // check how it looks, may be too much
      tableRowHoverBackgroundColor: '#545454',
      toolBorderColor: '#1e1e1e',
      toolDragTargetColor: '#555555',
      toolDragTargetOutlineColor: '#888888',
      toolHeaderBackgroundColor: '#1e1e1e',
      toolHeaderTextColor: '#fff',
    }, */
  'dātma light': {
    // Unused by UX2, but used by BlackBoxSSRTools:
    alternatingTableRowsBackgroundColor1: '#f5f5f5',
    alternatingTableRowsBackgroundColor2: '#e0e0e0',
    tableBorderColor: 'transparent',
    tableCellSortLinkColor: '#ffffff',
    tableCellTextColor: '#000000',
    tableCellTextLinkColor: '#512DA8',
    tableHeaderCellColor: '#616161',
    tableHeaderTextColor: '#ffffff',
    tableLineColor: '#E0E0E0',
    toolBackgroundColor: '#fff',
    toolFieldBackgroundColor: '#ffffff',
    toolFieldTextColor: '#000',
    toolIconColor: '#9e9e9e',
    toolInputBackgroundColor: '#f4f4f4',
    toolInputTextColor: '#000',
    toolPulldownBackgroundColor: '#fff',
    toolPulldownTextColor: '#000',
    toolScrollArrowsColor: '#000',
    toolScrollBarBackgroundColor: '#fafafa',
    toolScrollBarColor: '#64B5F6',
    toolTextColor: '#000000',

    accentColor: '#1976d2',
    alternatingTableRowsBackgroundColor: '#eee',
    appScrollBarBackgroundColor: '#fafafa',
    appScrollBarColor: '#64B5F6',
    baseBackgroundColor: '#ebebeb',
    baseHeaderBackgroundColor: '#000',
    baseHeaderIconColor: '#757575',
    baseHeaderTextColor: '#c6c6c6',
    buttonBackgroundColor: '#1976d2',
    buttonBorderColor: '#1976d2',
    buttonTextColor: '#ffffff',
    secondaryButtonBackgroundColor: '#b2b0b0',
    secondaryButtonBorderColor: '#000000',
    secondaryButtonTextColor: '#ffffff',
    confirmationPopupBackgroundColor: '#707070',
    confirmationPopupTextColor: '#ffffff',
    errorBackgroundColor: '#FFEBEE',
    errorTextColor: '#D32F2F',
    inputElementBorderColor: '#64B5F6',
    modalBackgroundColor: '#fff',
    modalIconColor: '#888',
    modalTextColor: '#000',
    plotColor01: '#1976d2',
    plotColor02: '#547791',
    plotColor03: '#78d2cf',
    plotColor04: '#547791',
    productLogoColor: '#fff',
    spinnerStrokeColor: '#9575CD',
    tableRowHoverBackgroundColor: '#BBDEFB',
    toolBorderColor: '#000',
    toolDragTargetColor: '#555555',
    toolDragTargetOutlineColor: '#888888',
    toolHeaderBackgroundColor: '#fff',
    toolHeaderTextColor: '#000',
    generalDescriptorTextColor: '#8D8B8B',
  },
  'MediumGray': {
    // Unused by UX2, but used by BlackBoxSSRTools:
    alternatingTableRowsBackgroundColor1: '#dff1f6',
    alternatingTableRowsBackgroundColor2: '#e0e0e0',
    tableBorderColor: '#000000',
    tableCellSortLinkColor: '#ffffff',
    tableCellTextColor: '#000000',
    tableCellTextLinkColor: '#154562',
    tableHeaderCellColor: '#83a6bb',
    tableHeaderTextColor: '#ffffff',
    tableLineColor: '#000000',
    toolBackgroundColor: '#e0e0e0',
    toolFieldBackgroundColor: '#ffffff',
    toolFieldTextColor: '#547791',
    toolIconColor: '#547791',
    toolInputBackgroundColor: '#fff',
    toolInputTextColor: '#000',
    toolPulldownBackgroundColor: '#fff',
    toolPulldownTextColor: '#000',
    toolScrollArrowsColor: '#000',
    toolScrollBarBackgroundColor: '#bebebe',
    toolScrollBarColor: '#83a6bb',
    toolTextColor: '#000000',

    accentColor: '#00a7e1',
    alternatingTableRowsBackgroundColor: '#dff1f6',
    appScrollBarBackgroundColor: '#7e7e7e',
    appScrollBarColor: '#83a6bb',
    baseBackgroundColor: '#707070',
    baseHeaderBackgroundColor: '#eeeeee',
    baseHeaderIconColor: '#547791',
    baseHeaderTextColor: '#547791',
    buttonBackgroundColor: '#83a6bb',
    buttonBorderColor: '#000000',
    buttonTextColor: '#ffffff',
    secondaryButtonBackgroundColor: '#83a6bb',
    secondaryButtonBorderColor: '#000000',
    secondaryButtonTextColor: '#ffffff',
    confirmationPopupBackgroundColor: '#707070',
    confirmationPopupTextColor: '#ffffff',
    errorBackgroundColor: '#fe4a49',
    errorTextColor: '#ffffff',
    inputElementBorderColor: '#000000',
    modalBackgroundColor: '#e0e0e0',
    modalIconColor: '#547791',
    modalTextColor: '#547791',
    plotColor01: '#83a6bb',
    plotColor02: '#547791',
    plotColor03: '#78d2cf',
    plotColor04: '#547791',
    productLogoColor: '#547791',
    spinnerStrokeColor: '#547791',
    tableRowHoverBackgroundColor: '#bfe4ed',
    toolBorderColor: '#000',
    toolDragTargetColor: '#555555',
    toolDragTargetOutlineColor: '#888888',
    toolHeaderBackgroundColor: '#eeeeee',
    toolHeaderTextColor: '#547791',
    generalDescriptorTextColor: '#8D8B8B',
  },
  'Midnight': {
    // Unused by UX2, but used by BlackBoxSSRTools:
    alternatingTableRowsBackgroundColor1: '#444',
    alternatingTableRowsBackgroundColor2: '#333',
    tableBorderColor: '#83a6bb',
    tableCellSortLinkColor: '#000',
    tableCellTextColor: '#FFEBC9',
    tableCellTextLinkColor: '#FFEBC9',
    tableHeaderCellColor: '#b5cad6',
    tableHeaderTextColor: '#000',
    tableLineColor: '#83a6bb',
    toolBackgroundColor: '#222',
    toolFieldBackgroundColor: '#444',
    toolFieldTextColor: '#FFEBC9',
    toolIconColor: '#83a6bb',
    toolInputBackgroundColor: '#000',
    toolInputTextColor: '#fff',
    toolPulldownBackgroundColor: '#000',
    toolPulldownTextColor: '#fff',
    toolScrollArrowsColor: '#fff',
    toolScrollBarBackgroundColor: '#333',
    toolScrollBarColor: '#b5cad6',
    toolTextColor: '#83a6bb',

    accentColor: '#00a7e1',
    alternatingTableRowsBackgroundColor: '#333',
    appScrollBarBackgroundColor: '#333',
    appScrollBarColor: '#b5cad6',
    baseBackgroundColor: '#333',
    baseHeaderBackgroundColor: '#222',
    baseHeaderIconColor: '#83a6bb',
    baseHeaderTextColor: '#FFEBC9',
    buttonBackgroundColor: '#83a6bb',
    buttonBorderColor: '#83a6bb',
    buttonTextColor: '#000',
    secondaryButtonBackgroundColor: '#83a6bb',
    secondaryButtonBorderColor: '#83a6bb',
    secondaryButtonTextColor: '#000',
    confirmationPopupBackgroundColor: '#707070',
    confirmationPopupTextColor: '#ffffff',
    errorBackgroundColor: '#d62839',
    errorTextColor: '#ffffff',
    inputElementBorderColor: '#83a6bb',
    modalBackgroundColor: '#222',
    modalIconColor: '#83a6bb',
    modalTextColor: '#83a6bb',
    plotColor01: '#83a6bb',
    plotColor02: '#547791',
    plotColor03: '#78d2cf',
    plotColor04: '#547791',
    productLogoColor: '#83a6bb',
    spinnerStrokeColor: '#83a6bb',
    tableRowHoverBackgroundColor: '#bfe4ed',
    toolBorderColor: '#83a6bb',
    toolDragTargetColor: '#555555',
    toolDragTargetOutlineColor: '#888888',
    toolHeaderBackgroundColor: '#222',
    toolHeaderTextColor: '#83a6bb',
    generalDescriptorTextColor: '#8D8B8B',
  },
  'Bright': {
    // Unused by UX2, but used by BlackBoxSSRTools:
    alternatingTableRowsBackgroundColor1: '#eee',
    alternatingTableRowsBackgroundColor2: 'white',
    tableBorderColor: '#000',
    tableCellSortLinkColor: 'white',
    tableCellTextColor: '#000',
    tableCellTextLinkColor: '#000',
    tableHeaderCellColor: '#c8c8ce',
    tableHeaderTextColor: 'white',
    tableLineColor: '#000',
    toolBackgroundColor: 'white',
    toolFieldBackgroundColor: 'white',
    toolFieldTextColor: '#000',
    toolIconColor: '#000',
    toolInputBackgroundColor: '#fff',
    toolInputTextColor: '#000',
    toolPulldownBackgroundColor: '#fff',
    toolPulldownTextColor: '#000',
    toolScrollArrowsColor: '#000',
    toolScrollBarBackgroundColor: '#e6e6e6',
    toolScrollBarColor: '#c8c8ce',
    toolTextColor: '#000',

    accentColor: '#00a7e1',
    alternatingTableRowsBackgroundColor: '#dff1f6',
    appScrollBarBackgroundColor: '#e6e6e6',
    appScrollBarColor: '#c8c8ce',
    baseBackgroundColor: 'white',
    baseHeaderBackgroundColor: '#eee',
    baseHeaderIconColor: 'black',
    baseHeaderTextColor: 'black',
    buttonBackgroundColor: '#C8C8CE',
    buttonBorderColor: '#00171f',
    buttonTextColor: '000',
    secondaryButtonBackgroundColor: '#C8C8CE',
    secondaryButtonBorderColor: '#00171f',
    secondaryButtonTextColor: '000',
    confirmationPopupBackgroundColor: 'rgba(0, 0, 0, 0.85)',
    confirmationPopupTextColor: '#f8fcfd',
    errorBackgroundColor: '#db162f',
    errorTextColor: '#fff',
    inputElementBorderColor: '#00171f',
    modalBackgroundColor: 'white',
    modalIconColor: '#C8C8CE',
    modalTextColor: 'black',
    plotColor01: '#1e81bb',
    plotColor02: '#FF7F0E',
    plotColor03: '#2CA02C',
    plotColor04: '#D62728',
    productLogoColor: '#1e81bb',
    spinnerStrokeColor: 'black',
    tableRowHoverBackgroundColor: '#bfe4ed',
    toolBorderColor: '#00171f',
    toolDragTargetColor: '#00eaff',
    toolDragTargetOutlineColor: '#0f7de4',
    toolHeaderBackgroundColor: '#eee',
    toolHeaderTextColor: '#000',
    generalDescriptorTextColor: '#8D8B8B',
  },
  'NightMarsh': {
    // Unused by UX2, but used by BlackBoxSSRTools:
    alternatingTableRowsBackgroundColor1: '#444',
    alternatingTableRowsBackgroundColor2: '#333',
    tableBorderColor: '#3C937E',
    tableCellSortLinkColor: '#000',
    tableCellTextColor: '#FFEBC9',
    tableCellTextLinkColor: '#FFEBC9',
    tableHeaderCellColor: '#53b9a0',
    tableHeaderTextColor: '#000',
    tableLineColor: '#3C937E',
    toolBackgroundColor: '#222',
    toolFieldBackgroundColor: '#444',
    toolFieldTextColor: '#FFEBC9',
    toolIconColor: '#3C937E',
    toolInputBackgroundColor: '#000',
    toolInputTextColor: '#fff',
    toolPulldownBackgroundColor: '#000',
    toolPulldownTextColor: '#fff',
    toolScrollArrowsColor: '#fff',
    toolScrollBarBackgroundColor: '#333',
    toolScrollBarColor: '#53b9a0',
    toolTextColor: '#3C937E',

    accentColor: '#00a7e1',
    alternatingTableRowsBackgroundColor: '#333',
    appScrollBarBackgroundColor: '#333',
    appScrollBarColor: '#53b9a0',
    baseBackgroundColor: '#333',
    baseHeaderBackgroundColor: '#222',
    baseHeaderIconColor: '#3C937E',
    baseHeaderTextColor: '#FFEBC9',
    buttonBackgroundColor: '#3C937E',
    buttonBorderColor: '#3C937E',
    buttonTextColor: '#000',
    secondaryButtonBackgroundColor: '#3C937E',
    secondaryButtonBorderColor: '#3C937E',
    secondaryButtonTextColor: '#000',
    confirmationPopupBackgroundColor: '#707070',
    confirmationPopupTextColor: '#ffffff',
    errorBackgroundColor: '#d64045',
    errorTextColor: '#ffffff',
    inputElementBorderColor: '#3C937E',
    modalBackgroundColor: '#222',
    modalIconColor: '#3C937E',
    modalTextColor: '#3C937E',
    plotColor01: '#3C937E',
    plotColor02: '#315F52',
    plotColor03: '#78d2cf',
    plotColor04: '#547791',
    productLogoColor: '#3C937E',
    spinnerStrokeColor: '#3C937E',
    tableRowHoverBackgroundColor: '#bfe4ed',
    toolBorderColor: '#3C937E',
    toolDragTargetColor: '#555555',
    toolDragTargetOutlineColor: '#888888',
    toolHeaderBackgroundColor: '#222',
    toolHeaderTextColor: '#3C937E',
    generalDescriptorTextColor: '#8D8B8B',
  },
  'Creamy': {
    // Unused by UX2, but used by BlackBoxSSRTools:
    alternatingTableRowsBackgroundColor1: '#fbe9c5',
    alternatingTableRowsBackgroundColor2: '#fef9ef',
    tableBorderColor: '#57200e',
    tableCellSortLinkColor: '#fdf1d9',
    tableCellTextColor: '#000',
    tableCellTextLinkColor: '#000',
    tableHeaderCellColor: '#e78768',
    tableHeaderTextColor: '#fdf1d9',
    tableLineColor: '#57200e',
    toolBackgroundColor: '#fef9ef',
    toolFieldBackgroundColor: 'white',
    toolFieldTextColor: '#000',
    toolIconColor: '#fdf1d9',
    toolInputBackgroundColor: '#fff',
    toolInputTextColor: '#000',
    toolPulldownBackgroundColor: '#fff',
    toolPulldownTextColor: '#000',
    toolScrollArrowsColor: '#000',
    toolScrollBarBackgroundColor: '#fceccd',
    toolScrollBarColor: '#e78768',
    toolTextColor: '#C6785F',

    accentColor: '#ffcb77',
    alternatingTableRowsBackgroundColor: '#fbe9c5',
    appScrollBarBackgroundColor: '#fceccd',
    appScrollBarColor: '#e78768',
    baseBackgroundColor: '#fef9ef',
    baseHeaderBackgroundColor: '#e78768',
    baseHeaderIconColor: '#fdf1d9',
    baseHeaderTextColor: '#000',
    buttonBackgroundColor: '#e78768',
    buttonBorderColor: '#000',
    buttonTextColor: '#fdf1d9',
    secondaryButtonBackgroundColor: '#e78768',
    secondaryButtonBorderColor: '#000',
    secondaryButtonTextColor: '#fdf1d9',
    confirmationPopupBackgroundColor: 'rgba(0, 0, 0, 0.8)',
    confirmationPopupTextColor: '#fffefc',
    errorBackgroundColor: '#df2a48',
    errorTextColor: '#fff',
    inputElementBorderColor: '#00171f',
    modalBackgroundColor: '#fef9ef',
    modalIconColor: '#C6785F',
    modalTextColor: '#C6785F',
    plotColor01: '#78d2cf',
    plotColor02: '#e78768',
    plotColor03: '#fed97f',
    plotColor04: '#D62728',
    productLogoColor: '#fdf1d9',
    spinnerStrokeColor: '#e78768',
    tableRowHoverBackgroundColor: '#f9db9f',
    toolBorderColor: '#00171f',
    toolDragTargetColor: '#00eaff',
    toolDragTargetOutlineColor: '#0f7de4',
    toolHeaderBackgroundColor: '#e78768',
    toolHeaderTextColor: '#fdf1d9',
    generalDescriptorTextColor: '#8D8B8B',
  },
  'Rusty': {
    // Unused by UX2, but used by BlackBoxSSRTools:
    alternatingTableRowsBackgroundColor1: '#453e4e',
    alternatingTableRowsBackgroundColor2: '#292929',
    tableBorderColor: '#707070',
    tableCellSortLinkColor: '#e5e4e2',
    tableCellTextColor: '#e5e4e2',
    tableCellTextLinkColor: '#e5e4e2',
    tableHeaderCellColor: '#6a4d5d',
    tableHeaderTextColor: '#e5e4e2',
    tableLineColor: '#707070',
    toolBackgroundColor: '#2f2229',
    toolFieldBackgroundColor: '#453e4e',
    toolFieldTextColor: '#e5e4e2',
    toolIconColor: '#e5e4e2',
    toolInputBackgroundColor: '#000',
    toolInputTextColor: '#fff',
    toolPulldownBackgroundColor: '#000',
    toolPulldownTextColor: '#fff',
    toolScrollArrowsColor: '#fff',
    toolScrollBarBackgroundColor: 'black',
    toolScrollBarColor: '#6a4d5d',
    toolTextColor: '#e5e4e2',

    accentColor: '#2f2229',
    alternatingTableRowsBackgroundColor: '#333',
    appScrollBarBackgroundColor: 'black',
    appScrollBarColor: '#6a4d5d',
    baseBackgroundColor: 'black',
    baseHeaderBackgroundColor: '#2f2229',
    baseHeaderIconColor: '#baa0af',
    baseHeaderTextColor: '#e5e4e2',
    buttonBackgroundColor: '#6a4d5d',
    buttonBorderColor: '#707070',
    buttonTextColor: '#e5e4e2',
    secondaryButtonBackgroundColor: '#6a4d5d',
    secondaryButtonBorderColor: '#707070',
    secondaryButtonTextColor: '#e5e4e2',
    confirmationPopupBackgroundColor: 'rgba(49, 49, 49, 0.95)',
    confirmationPopupTextColor: '#e5e5e5',
    errorBackgroundColor: '#ba2d0b',
    errorTextColor: '#fff',
    iconColor: '#e5e4e2',
    inputElementBorderColor: '#707070',
    modalBackgroundColor: '#1a1a1a',
    modalIconColor: '#a17d91',
    modalTextColor: '#e5e4e2',
    plotColor01: '#1F77B4',
    plotColor02: '#FF7F0E',
    plotColor03: '#2CA02C',
    plotColor04: '#D62728',
    productLogoColor: '#baa0af',
    spinnerStrokeColor: '#e5e4e2',
    tableRowHoverBackgroundColor: '#444',
    toolBorderColor: '#707070',
    toolDragTargetColor: '#333',
    toolDragTargetOutlineColor: '#555',
    toolHeaderBackgroundColor: '#6a4d5d',
    toolHeaderTextColor: '#e5e4e2',
  },
  'ODA 2022': {
    // Unused by UX2, but used by BlackBoxSSRTools:
    alternatingTableRowsBackgroundColor1: '#dff1f6',
    alternatingTableRowsBackgroundColor2: '#e0e0e0',
    tableBorderColor: '#556e7f',
    tableCellSortLinkColor: '#ffffff',
    tableCellTextColor: '#000000',
    tableCellTextLinkColor: '#154562',
    tableHeaderCellColor: '#32739b',
    tableHeaderTextColor: '#ffffff',
    tableLineColor: '#000000',
    toolBackgroundColor: '#fff',
    toolFieldBackgroundColor: '#ffffff',
    toolFieldTextColor: '#32739b',
    toolIconColor: '#32739b',
    toolInputBackgroundColor: '#fff',
    toolInputTextColor: '#000',
    toolPulldownBackgroundColor: '#fff',
    toolPulldownTextColor: '#000',
    toolScrollArrowsColor: '#000',
    toolScrollBarBackgroundColor: '#dadada',
    toolScrollBarColor: '#32739b',
    toolTextColor: '#000000',

    accentColor: '#bfe6ff',
    alternatingTableRowsBackgroundColor: '#ebf7ff',
    appScrollBarBackgroundColor: '#dadada',
    appScrollBarColor: '#32739b',
    baseBackgroundColor: '#fff',
    baseHeaderBackgroundColor: '#dadada',
    baseHeaderIconColor: '#32739b',
    baseHeaderTextColor: '#111619',
    buttonBackgroundColor: '#32739b',
    buttonBorderColor: '#556e7f',
    buttonTextColor: '#ffffff',
    secondaryButtonBackgroundColor: '#32739b',
    secondaryButtonBorderColor: '#556e7f',
    secondaryButtonTextColor: '#ffffff',
    confirmationPopupBackgroundColor: '#fff',
    confirmationPopupTextColor: '#111619',
    errorBackgroundColor: '#f0386b',
    errorTextColor: '#ffffff',
    inputElementBorderColor: '#556e7f',
    modalBackgroundColor: '#fff',
    modalIconColor: '#32739b',
    modalTextColor: '#32739b',
    plotColor01: 'e6af2e',
    plotColor02: '#ff6666',
    plotColor03: '#06a77d',
    plotColor04: '#26f0f1',
    productLogoColor: '#32739b',
    spinnerStrokeColor: '#556e7f',
    tableRowHoverBackgroundColor: '#bfe6ff',
    toolBorderColor: '#556e7f',
    toolDragTargetColor: '#dadada',
    toolDragTargetOutlineColor: '#888888',
    toolHeaderBackgroundColor: '#dadada',
    toolHeaderTextColor: '#32739b',
    generalDescriptorTextColor: '#8D8B8B',
  },
}

export const defaultThemeName = () => {
  return getEnv('VUE_APP_UX3') === 'true' ? 'UX3' :
    getEnv('VUE_APP_PROVIDENCE_BUILD') === 'true' ? 'MediumGray' :
      'dātma light'
}

export const isThemeLight = {
  'dātma light': true,
  'MediumGray': true,
  'Bright': true,
  'Creamy': true,
  'ODA 2022': true,
}

// for UX3
const datmaBrandingColors = {
  Grey100: '#F4F4F4',
  Grey200: '#E7E7E7',
  Grey300: '#D9D9D9',
  Grey400: '#C6C6C6',
  Grey500: '#B2B0B0',
  Grey600: '#8D8B8B',
  Grey700: '#6F6D6D',
  Grey800: '#4C4A4A',
  Grey900: '#2A2828',
  Primary: '#1976D2',
  PrimaryLight: '#90CAF9',
  PrimaryDark: '#0D47A1',
  Secondary: '#9575CD',
  SecondaryLight: '#D1C4E9',
  SecondaryDark: '#512DA8',
  Tertiary: '#0097A7',
  TertiaryLight: '#80DEEA',
  TertiaryDark: '#006064',
  SuccessGreenDark: '#43A047',
  SuccessGreenLight: '#E8F5E9',
  WarningYellowDark: '#FBC02D',
  WarningYellowLight: '#FFF9C4',
  ErrorRedDark: '#EF5350',
  ErrorRedLight: '#FFEEBE',
  White: '#FFFFFF',
  Black: '#000000',
  MarketingMagenta: '#DF01BE',
  MarketingOrange: '#FFAA0F',
  BlendedOrange: '#F46D4E',
  datmaBase: '#08753A',
  datma360: '#8ABE1A',
  datmaFed: '#15929B',
  datmaWhy: '#83D2A3',
}

if (getEnv('VUE_APP_UX3') === 'true') {
  isThemeLight['UX3'] = true
  themeColors['UX3'] = {
    // Unused by UX2, but used by BlackBoxSSRTools:
    alternatingTableRowsBackgroundColor1: datmaBrandingColors.Grey100,
    alternatingTableRowsBackgroundColor2: datmaBrandingColors.Grey200,
    tableBorderColor: 'transparent',
    tableCellSortLinkColor: datmaBrandingColors.White,
    tableCellTextColor: datmaBrandingColors.Black,
    tableCellTextLinkColor: datmaBrandingColors.SecondaryDark,
    tableHeaderCellColor: datmaBrandingColors.Grey700,
    tableHeaderTextColor: datmaBrandingColors.White,
    tableLineColor: datmaBrandingColors.Grey200,
    toolBackgroundColor: datmaBrandingColors.White,
    toolFieldBackgroundColor: datmaBrandingColors.White,
    toolFieldTextColor: datmaBrandingColors.Black,
    toolIconColor: datmaBrandingColors.Grey600,
    toolInputBackgroundColor: datmaBrandingColors.Grey100,
    toolInputTextColor: datmaBrandingColors.Black,
    toolPulldownBackgroundColor: datmaBrandingColors.White,
    toolPulldownTextColor: datmaBrandingColors.Black,
    toolScrollArrowsColor: datmaBrandingColors.Black,
    toolScrollBarBackgroundColor: datmaBrandingColors.Grey100,
    toolScrollBarColor: datmaBrandingColors.PrimaryLight,
    toolTextColor: datmaBrandingColors.Black,

    accentColor: datmaBrandingColors.Primary,
    alternatingTableRowsBackgroundColor: datmaBrandingColors.Grey200,
    appScrollBarBackgroundColor: datmaBrandingColors.Grey100,
    appScrollBarColor: datmaBrandingColors.PrimaryLight,
    baseBackgroundColor: datmaBrandingColors.Grey200,
    baseHeaderBackgroundColor: datmaBrandingColors.Black,
    baseHeaderIconColor: datmaBrandingColors.Grey700,
    baseHeaderTextColor: datmaBrandingColors.Grey300,
    buttonBackgroundColor: datmaBrandingColors.Primary,
    buttonBorderColor: datmaBrandingColors.Primary,
    buttonTextColor: datmaBrandingColors.White,
    secondaryButtonBackgroundColor: datmaBrandingColors.Grey500,
    secondaryButtonBorderColor: datmaBrandingColors.Black,
    secondaryButtonTextColor: datmaBrandingColors.White,
    confirmationPopupBackgroundColor: datmaBrandingColors.Grey700,
    confirmationPopupTextColor: datmaBrandingColors.White,
    errorBackgroundColor: datmaBrandingColors.ErrorRedLight,
    errorTextColor: datmaBrandingColors.ErrorRedDark,
    inputElementBorderColor: datmaBrandingColors.PrimaryLight,
    modalBackgroundColor: datmaBrandingColors.White,
    modalIconColor: datmaBrandingColors.Grey600,
    modalTextColor: datmaBrandingColors.Black,
    plotColor01: datmaBrandingColors.Primary,
    plotColor02: datmaBrandingColors.Secondary,
    plotColor03: datmaBrandingColors.Tertiary,
    plotColor04: datmaBrandingColors.MarketingMagenta,
    productLogoColor: datmaBrandingColors.Black,
    spinnerStrokeColor: datmaBrandingColors.PrimaryLight,
    tableRowHoverBackgroundColor: datmaBrandingColors.SecondaryLight,
    toolBorderColor: datmaBrandingColors.Black,
    toolDragTargetColor: datmaBrandingColors.Grey800,
    toolDragTargetOutlineColor: datmaBrandingColors.Grey600,
    toolHeaderBackgroundColor: datmaBrandingColors.White,
    toolHeaderTextColor: datmaBrandingColors.Grey600,
    generalDescriptorTextColor: datmaBrandingColors.Grey500,
    generalDatumTextColor: datmaBrandingColors.primaryBlack,

    Grey100: datmaBrandingColors.Grey100,
    Grey200: datmaBrandingColors.Grey200,
    Grey300: datmaBrandingColors.Grey300,
    Grey400: datmaBrandingColors.Grey400,
    Grey500: datmaBrandingColors.Grey500,
    Grey600: datmaBrandingColors.Grey600,
    Grey700: datmaBrandingColors.Grey700,
    Grey800: datmaBrandingColors.Grey800,
    Grey900: datmaBrandingColors.Grey900,
    Primary: datmaBrandingColors.Primary,
    PrimaryLight: datmaBrandingColors.PrimaryLight,
    PrimaryDark: datmaBrandingColors.PrimaryDark,
    Secondary: datmaBrandingColors.Secondary,
    SecondaryLight: datmaBrandingColors.SecondaryLight,
    SecondaryDark: datmaBrandingColors.SecondaryDark,
    Tertiary: datmaBrandingColors.Tertiary,
    TertiaryLight: datmaBrandingColors.TertiaryLight,
    TertiaryDark: datmaBrandingColors.TertiaryDark,
    SuccessGreenDark: datmaBrandingColors.SuccessGreenDark,
    SuccessGreenLight: datmaBrandingColors.SuccessGreenLight,
    WarningYellowDark: datmaBrandingColors.WarningYellowDark,
    WarningYellowLight: datmaBrandingColors.WarningYellowLight,
    ErrorRedDark: datmaBrandingColors.ErrorRedDark,
    ErrorRedLight: datmaBrandingColors.ErrorRedLight,
    White: datmaBrandingColors.White,
    Black: datmaBrandingColors.Black,
    MarketingMagenta: datmaBrandingColors.MarketingMagenta,
    MarketingOrange: datmaBrandingColors.MarketingOrange,
    BlendedOrange: datmaBrandingColors.BlendedOrange,
    datmaBase: datmaBrandingColors.datmaBase,
    datma360: datmaBrandingColors.datma360,
    datmaFed: datmaBrandingColors.datmaFed,
    datmaWhy: datmaBrandingColors.datmaWhy,
  }
}

export const timeZones = [
  { name: 'Niue (UTC−11)', id: 'Pacific/Niue' },
  { name: 'Hawaii–Aleutian Islands (UTC−10)', id: 'Pacific/Honolulu' },
  { name: 'Alaska (UTC−9)', id: 'America/Anchorage' },
  { name: 'Pacific (UTC−8)', id: 'America/Los_Angeles' },
  { name: 'Mountain (UTC−7)', id: 'America/Denver' },
  { name: 'Central (UTC−6)', id: 'America/Chicago' },
  { name: 'Eastern (UTC−5)', id: 'America/New_York' },
  { name: 'Atlantic (UTC−4)', id: 'America/Halifax' },
  { name: 'Brasília (UTC−3)', id: 'America/Fortaleza' },
  { name: 'South Georgia (UTC−2)', id: 'Atlantic/South_Georgia' },
  { name: 'Cape Verde (UTC−1)', id: 'Atlantic/Cape_Verde' },
  { name: 'Western Europe (UTC)', id: 'Europe/London' },
  { name: 'Central Europe (UTC+1)', id: 'Europe/Berlin' },
  { name: 'Eastern Europe (UTC+2)', id: 'Europe/Helsinki' },
  { name: 'Moscow (UTC+3)', id: 'Europe/Moscow' },
  { name: 'Dubai (UTC+4)', id: 'Asia/Dubai' },
  { name: 'Maldives (UTC+5)', id: 'Indian/Maldives' },
  { name: 'India (UTC+5:30)', id: 'Asia/Kolkata' },
  { name: 'Kyrgyzstan (UTC+6)', id: 'Asia/Bishkek' },
  { name: 'Indochina (UTC+7)', id: 'Asia/Bangkok' },
  { name: 'China (UTC+8)', id: 'Asia/Shanghai' },
  { name: 'Japan (UTC+9)', id: 'Asia/Tokyo' },
  { name: 'Guam (UTC+10)', id: 'Pacific/Guam' },
  { name: 'Vanuatu (UTC+11)', id: 'Pacific/Noumea' },
  { name: 'New Zealand (UTC+12)', id: 'Pacific/Auckland' },
]

export const languageToLocale = {
  'en_US.UTF-8': enUS,  // English
  'fr_CA.UTF-8': frCA,  // French
  'tr_TR.UTF-8': trTR,  // Turkish
  'zh_CN.UTF-8': zhCN,  // Chinese (Simplified)
  'zh_TW.UTF-8': zhTW,  // Chinese (Traditional)
}

const dateFormatPreference = {
  'en_US.UTF-8': 'en-US',  // English
  'fr_CA.UTF-8': 'fr-CA',  // French
  'tr_TR.UTF-8': 'tr-TR',  // Turkish
  'zh_CN.UTF-8': 'zh-CN',  // Chinese (Simplified)
  'zh_TW.UTF-8': 'zh-TW',  // Chinese (Traditional)
}

// The keys represent different possibilities for what the browser may send
export const browserToLanguage = {
  'en': 'en_US.UTF-8',  // English
  'en-US': 'en_US.UTF-8',  // English
  'fr-CA': 'fr_CA.UTF-8',  // French
  'tr': 'tr_TR.UTF-8',  // Turkish
  'tr-TR': 'tr_TR.UTF-8',  // Turkish
  'zh': 'zh_CN.UTF-8',  // Chinese (Simplified)
  'zh-CN': 'zh_CN.UTF-8',  // Chinese (Simplified)
  'zh-TW': 'zh_TW.UTF-8',  // Chinese (Traditional)
}

export const calculateLastAccessDate = (timeOfLastAccess, store) => {
  const date = new Date(timeOfLastAccess)
  const dateString = date.toLocaleString(dateFormatPreference[store.getters.profile.language], { timeZone: store.getters.profile.timeZone })
  return dateString
}

export const nameIsValidRE = /^[^"&%<>?@!#$^*;:'[\]{}/\\|]+$/i
export function sanitizeUrlSegment(segment) {
  if (!segment) { return '' }
  const regex = /["&%<>?@!#$^*;:'[\]{}/\\|\s]+/ig
  const trimEnds = /^[-"&%<>?@!#$^*;:'[\]{}/\\|\s]+|[-"&%<>?@!#$^*;:'[\]{}/\\|\s]+$/ig
  return segment.replace(regex, '-').replace(trimEnds, '').toLowerCase()
}

export function getDashboardUrl(displayNameOrId) {
  return `/user/${sanitizeUrlSegment(displayNameOrId)}/dashboard`
}

export function getProfileUrl(displayNameOrId) {
  return `/user/${sanitizeUrlSegment(displayNameOrId)}/profile`
}

// Copied from https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
export function getScrollbarWidth() {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll'
  outer.style.msOverflowStyle = 'scrollbar'
  document.body.appendChild(outer)
  const inner = document.createElement('div')
  outer.appendChild(inner)
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth)
  outer.parentNode.removeChild(outer)
  return scrollbarWidth
}

export function getSessionUrl(displayNameOrId, sessionNameOrId) {
  return `/user/${sanitizeUrlSegment(displayNameOrId)}/session/${sanitizeUrlSegment(sessionNameOrId)}`
}

export const base64DecodeBytes = (s) => Buffer.from(s, 'base64')
export const base64Decode = (s) => String.fromCharCode(...base64DecodeBytes(s))
export const base64GzipDecodeBytes = (s) => {
  try {
    const rawData = base64DecodeBytes(s)
    return pako.inflate(rawData)
  } catch (err) {
    console.log('pako.inflate: ', err)
  }
  return ''
}
export const base64GzipDecode = (s) => String.fromCharCode(...base64GzipDecodeBytes(s))

export function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(base64Decode(base64).split('')
    .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
    .join(''))
  return JSON.parse(jsonPayload)
}

export const addSpaceAfterComma = (s) => {
  const commaIndex = s.indexOf(',')
  if (commaIndex >= 0 && commaIndex + 1 < s.length && s.substring(commaIndex + 1, commaIndex + 2) != ' ') {
    return `${s.substring(0, commaIndex)}, ${s.substring(commaIndex + 1)}`
  }
  return s
}

export const shortenId = (id) => {
  const len = id.length
  if (len <= 11) { return id }
  return `${id.substring(0, 4)}…${id.substring(len - 4, len)}`
}

// Taken from: https://v3.vuejs.org/api/refs-api.html#customref
export function useDebouncedRef(value, delay = 200) {
  let timeout
  return customRef((track, trigger) => {
    return {
      get() {
        track()
        return value
      },
      set(newValue) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          value = newValue
          trigger()
        }, delay)
      },
    }
  })
}

export const tipParagraphStyleFunc = (store) => {
  return {
    backgroundColor: themeColors[store.getters.currentThemeName].confirmationPopupBackgroundColor,
    color: themeColors[store.getters.currentThemeName].confirmationPopupTextColor,
    borderColor: themeColors[store.getters.currentThemeName].buttonBackgroundColor,
  }
}

export const onSignOut = () => {
  window.access_token = ''
  window.refresh_token = ''
  localStorage.clear()
  const keycloakBaseUrl = getEnv('VUE_APP_KEYCLOAK_BASE_URL')
  const url = `${keycloakBaseUrl}/protocol/openid-connect/logout?redirect_uri=${encodeURIComponent(location.origin)}`
  window.location.href = url
}

// Adapted from: https://dmitripavlutin.com/timeout-fetch-request/
export const fetchWithTimeout = async (resource, options = {}) => {
  const requestTimeout = getEnv('VUE_APP_REQUEST_TIMEOUT_MS') || 15000 // 15000 ms = 15 seconds
  const { timeout = requestTimeout } = options

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  })
  clearTimeout(id)
  return response
}

export default {}
