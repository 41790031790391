// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, ref } from 'vue'
import { EmitterMessage } from 'emitter-io'

import { store } from '@/store'
import getEnv from '@/utils/env'

import { noBackendSetup } from './noBackend.js'
import { withBackendSetup } from './withBackend.js'
import rpc from './rpc.js'
import session from './sessions.js'
import users from './users.js'

export default {
  install: (app) => {
    const { client, publishToKey } = (getEnv('VUE_APP_NO_BACKEND') === 'true') ?
      noBackendSetup(app) : withBackendSetup(app)

    const {
      rpcAfterActions,
      rpcBeforeActions,
      rpcHandlers,
    } = rpc.setup(app, { client, publishToKey })

    const {
      sessionAfterActions,
      sessionBeforeActions,
      sessionHandlers,
    } = session.setup(app, { client, publishToKey })

    const {
      usersAfterActions,
      usersBeforeActions,
      usersHandlers,
    } = users.setup(app, { client, publishToKey })

    const subscribedBeforeActions = ref([  // ref here makes development easier.
      rpcBeforeActions.value,
      sessionBeforeActions.value,
      usersBeforeActions.value,
    ])

    const subscribedAfterActions = ref([  // ref here makes development easier.
      rpcAfterActions.value,
      sessionAfterActions.value,
      usersAfterActions.value,
    ])

    store.subscribeAction({
      before: (action, state) => {
        let foundAction = false
        subscribedBeforeActions.value.forEach((lookup) => {
          const f = lookup[action.type]
          if (f) {
            f(action.payload, state)
            foundAction = true
          }
        })
        if (foundAction ||
          rpcAfterActions.value[action.type] ||
          sessionAfterActions.value[action.type] ||
          usersAfterActions.value[action.type]) { return }
        console.log('UNHANDLED subscribedAction:', action, state)
      },
      after: (action, state) => {
        subscribedAfterActions.value.forEach((lookup) => {
          const f = lookup[action.type]
          if (f) {
            f(action.payload, state)
          }
        })
      },
    })

    const additionalHandlers = ref({})

    const allHandlers = computed(() => {
      return {
        ...additionalHandlers.value,
        ...rpcHandlers.value,
        ...sessionHandlers.value,
        ...usersHandlers.value,
      }
    })

    const rawHandler = (msg) => {
      const f = allHandlers.value[msg.channel]
      if (f) {
        f(msg)
        return
      }
      if (!msg.channel.includes('/ydoc/')) {
        console.log(`UNHANDLED message: channel=${msg.channel}, message='${msg.asString()}'`)
      }
    }

    const partRE = /\/part-(\d+)-of-(\d+)\/$/
    const msgBuffer = {}
    const bufferedHandler = (msg) => {
      const m = partRE.exec(msg.channel)
      if (!m) {
        return rawHandler(msg)
      }

      const channel = msg.channel.substring(0, m.index + 1)  // include trailing slash.
      msgBuffer[channel] = `${msgBuffer[channel] || ''}${msg.asString()}`
      if (m[1] === m[2]) {  // message complete.
        const newMsg = new EmitterMessage({ topic: channel, payload: msgBuffer[channel] })
        msgBuffer[channel] = ''
        return rawHandler(newMsg)
      }
    }

    client.on('message', (msg) => {
      if (!msg.channel.includes('/ydoc/')) {
        console.log(`pubsub got message("${msg.channel}"): ${msg.asString().length} bytes`)
      }
      bufferedHandler(msg)
    })

    const registerPubsubHandler = (channel, f) => {
      console.log(`registerPubsubHandler: channel=${channel}/`)
      additionalHandlers.value[`${channel}/`] = f
    }
    app.provide('registerPubsubHandler', registerPubsubHandler)

    const unregisterPubsubHandler = (channel) => {
      console.log(`unregisterPubsubHandler: channel=${channel}/`)
      delete additionalHandlers.value[`${channel}/`]
    }
    app.provide('unregisterPubsubHandler', unregisterPubsubHandler)
  },
}
