<template>
  <header :style="headerStyle">
    <h1>
      <router-link :to="routeToMostRecentSession">
        <my-patient-logo v-if="featureToggles.providenceBuild" class="my-patient-logo"></my-patient-logo>
        <datma-logo v-else class="datma-logo"></datma-logo>
        <img v-if="featureToggles.providenceBuild" class="customer-logo" src="/assets/images/providence_color_small.webp">
        <img v-if="featureToggles.guardantBuild" class="customer-logo-more-padding"
          src="/assets/images/guardant-logo.svg">
      </router-link>
    </h1>
    <div class="session-menu">
      <slot></slot>
      <toggle-theme-icon class="toggle-theme header-icon" @click="toggleTheme" role="button" tabindex="0"
        @keyup.enter="toggleTheme"></toggle-theme-icon>
    </div>
    <nav>
      <router-link v-if="!featureToggles.noBackend" :to="routeToDashboard" v-slot="{ isActive }" class="header-button">
        <dashboard-icon class="header-icon" :class="isActive && 'disabled'"></dashboard-icon>
      </router-link>
      <router-link v-if="!featureToggles.noProfile" :to="routeToProfile" v-slot="{ isActive }" class="header-button">
        <profile-icon class="header-icon" :class="isActive && 'disabled'"></profile-icon>
      </router-link>
    </nav>
  </header>
</template>

<script setup>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed } from 'vue'
import { useStore } from 'vuex'

import DashboardIcon from '@/components/ui/icons/DashboardIcon.vue'
import DatmaLogo from '@/components/ui/icons/DatmaLogo.vue'
import MyPatientLogo from '@/components/ui/icons/MyPatientLogo.vue'
import ProfileIcon from '@/components/ui/icons/ProfileIcon.vue'
import { themeColors } from '@/common/shared.js'

const store = useStore()

const featureToggles = computed(() => store.getters.featureToggles)

const headerStyle = computed(() => ({
  backgroundColor: themeColors[store.getters.currentThemeName].baseHeaderBackgroundColor,
}))

const routeToDashboard = computed(() => store.getters.dashboardUrl)
const routeToMostRecentSession = computed(() => store.getters.mostRecentSessionUrlOrDashboard)
const routeToProfile = computed(() => store.getters.profileUrl)

const toggleTheme = () => store.dispatch('toggleTheme')
</script>

<style lang="scss" scoped>
@import '@/common/shared.scss';

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  background-color: #ccc;
  // margin: 0 10%;
  position: fixed;
  z-index: 10;
  width: 100%;
}

h1 {
  margin: 0;
  max-height: $header-height;
}

.my-patient-logo {
  padding: calc(#{$header-height} / 8);
  height: $header-height;
}

.customer-logo {
  padding: calc(#{$header-height} / 4);
  padding-left: 0;
  height: $header-height;
}

.customer-logo-more-padding {
  padding: calc(#{$header-height} / 4);
  padding-left: 1rem;
  height: $header-height;
}

nav {
  max-height: $header-height;
  margin-top: calc(#{$header-height} / 12);
}

.header-icon {
  display: inline-block;
  width: calc(#{$header-height} / 1.5);
  height: calc(#{$header-height} / 1.5);
  padding: calc(#{$header-height} / 6);
  margin: 0 calc(#{$header-height} / 8);
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
}



.disabled {
  cursor: default;
  fill: grey;

  &:hover {
    transform: none;
  }
}

.header-button:focus>div {
  transform: scale(1.2);
}

.header-button:focus:not(:focus-visible)>div {
  transform: scale(1);
}

.session-menu {
  min-width: 30%;
  display: flex;
  align-items: center;
}

.session-menu .toggle-theme:only-child {
  margin-left: 100%;
}

@media (max-width: 1100px) {

  .customer-logo,
  .customer-logo-more-padding {
    display: none;
  }
}

@media (max-width: 800px) {
  .toggle-theme {
    display: none;
  }

  .session-menu {
    min-width: auto;
  }
}

@media (max-width: 750px) {
  .toggle-theme {
    display: inline-block;
  }
}

@media (max-width: 430px) {
  .toggle-theme {
    display: none;
  }
}

@media (max-width: 285px) {

  .my-patient-logo,
  .datma-logo {
    display: none;
  }
}

@media (max-width: 160px) {
  .header-button:first-of-type {
    display: none;
  }
}
</style>
